import { useSpring, animated } from '@react-spring/web'

import { useTranslate } from '/machinery/I18n'

import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingXxl } from '/features/buildingBlocks/Heading'
import { TextLg } from '/features/buildingBlocks/Text'
import { ScrollIndicator } from '/features/buildingBlocks/ScrollIndicator'
import { ContainerMd } from '/features/buildingBlocks/Container'

import styles from './HeroPrimary.css'

export function HeroPrimary({ image, title, text, layoutClassName }) {
  const { __ } = useTranslate()

  const springConfig = {
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(40px)' },
    config: { tension: 100, friction: 35 },
  }

  const headingSpring = useSpring({ ...springConfig, delay: 200 })
  const textSpring = useSpring({ ...springConfig, delay: 400 })
  const scrollIndicatorSpring = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-20px)' },
    config: { tension: 100, friction: 35 },
    delay: 600,
  })

  return (
    <header className={cx(styles.component, layoutClassName)} data-x='hero'>
      <ContainerMd layoutClassName={styles.containerLayout}>
        <div className={styles.content}>
          <animated.div className={styles.heading} style={headingSpring}>
            <HeadingXxl h='1'>{title}</HeadingXxl>
          </animated.div>

          <animated.div style={textSpring}>
            <TextLg>{text}</TextLg>
          </animated.div>

          <animated.div style={scrollIndicatorSpring} className={styles.scrollIndicator}>
            <ScrollIndicator title={__`discover-more`} />
          </animated.div>
        </div>
      </ContainerMd>
      {image && (
        <div className={styles.imageContainer}>
          <ImageCover
            aspectRatio={16 / 9}
            sizes='100vw'
            imgProps={{ fetchpriority: 'high' }}
            layoutClassName={styles.imageLayout}
            {...{ image }}
          />
        </div>
      )}
      <div role='presentation' className={styles.gradient} />
    </header>
  )
}
